import React from 'react'
import classnames from 'classnames'
import { css } from '@emotion/core'
import theme from '../styles/theme'

const laptopStyles = css`
  font-size: 1rem;
  width: 44em;
  height: 26em;
  background-image: url('/images/laptop/laptop.png');
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  .zoom {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 10em;
    height: 10em;
    border-radius: 50%;
    box-shadow: 2px 2px 17px -6px #000;
    background-size: cover;
    background-color: #fff;
  }

  .viewport {
    position: absolute;
    z-index: 1;
    top: 1.4em;
    right: 5.1em;
    bottom: 3.4em;
    left: 5.2em;
    background-color: ${theme.lightGray};
    background-size: 100%;
    background-repeat: no-repeat;
  }
`

const Laptop = ({ className, image, zoomImage }) => {
  return (
    <div css={laptopStyles} className={classnames('laptop', className)}>
      {zoomImage && (
        <div
          className="zoom"
          style={{ backgroundImage: `url('https://questionable.io/images/laptop/${zoomImage}')` }}
        />
      )}
      <div className="viewport" style={{ backgroundImage: `url('https://questionable.io/images/laptop/${image}')` }} />
    </div>
  )
}

export default Laptop
