import React from 'react'
import Link from 'gatsby-link'
import { css } from '@emotion/core'
import Centered from '../Centered'
import theme from '../styles/theme'

const breakpointOne = '600px'
const breakpointTwo = '800px'
const breakpointThree = theme.panelBreakpoint

const heroStyles = css`
  position: relative;

  &::before {
    content: '';
    position: absolute;

    @media (min-width: ${breakpointTwo}) {
      left: 23em;
      bottom: -2em;
      background-image: url('https://questionable.io/images/coder.svg');
      background-repeat: no-repeat;
      background-size: contain;
      width: 29em;
      height: 20em;
    }

    @media (min-width: ${breakpointThree}) {
      left: auto;
      right: -8em;
      bottom: -2em;
      width: 35em;
      height: 24em;
    }
  }

  .hero-verbiage {
    max-width: 27em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;

    .heading {
      margin: 0;
      font-size: 2.3em;
      line-height: 1em;
    }

    @media (min-width: ${breakpointOne}) {
      .heading {
        font-size: 2.6em;
        line-height: 1em;
      }
    }

    @media (min-width: ${breakpointTwo}) {
      font-size: 1.3rem;
      min-height: 18rem;
      text-align: left;
      margin-left: 0;
      margin-right: 0;
    }

    @media (min-width: ${breakpointThree}) {
      font-size: 1.3rem;
      min-height: 23rem;

      .heading {
        font-size: 3.7em;
      }
    }
  }
`

const Hero = () => (
  <Centered css={heroStyles}>
    <div className="hero-verbiage">
      <h1 className="heading">
        Tests for
        <br />
        Software Engineers
      </h1>
      <p>
        <strong>Create Your Own, Or Use Ours.</strong>
      </p>
      <div>
        <Link to="/signup" className="action-button">
          Start 14-Day Free Trial
        </Link>
      </div>
    </div>
  </Centered>
)

export default Hero
