import React from 'react'
import { css } from '@emotion/core'
import Icon from '../shared/ui/Icon'
import theme from '../styles/theme'

export function ArrowList({ children }) {
  return <div>{children}</div>
}

const arrowListItemStyles = css`
  position: relative;

  &:not(:first-child) {
    margin-top: 3em;
  }

  &:not(:last-child) {
    &::before,
    &::after {
      content: '';
      display: block;
      width: 30px;
      position: absolute;
      left: 0.65em;
    }
    &::before {
      background-image: url('https://questionable.io/images/string-point-dot.svg');
      background-position: bottom;
      top: 0;
      bottom: calc(-2em + 20px);
    }
    &::after {
      background-image: url('https://questionable.io/images/string-point-arrow.svg');
      bottom: -2em;
      height: 20px;
    }
  }

  .dot {
    background-color: ${theme.brightBlue};
    position: absolute;
    width: 3em;
    height: 3em;
    border-radius: 50%;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1.7em !important;
      height: 1.7em !important;
      fill: #fff;
    }
  }

  .content {
    margin-left: 4em;
    padding-top: 0.6em;
  }
`

export function ArrowListItem({ children, icon, title }) {
  return (
    <div css={arrowListItemStyles}>
      <div className="dot">
        <Icon icon={icon} />
      </div>
      <div className="content spacing">
        <h4>{title}</h4>
        {children}
      </div>
    </div>
  )
}
