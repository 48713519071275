import React from 'react'
import { css } from '@emotion/core'
import Icon from 'shared/ui/Icon'

const iconListStyles = css`
  list-style: none;
  padding-left: 1.3em;

  li {
    position: relative;
    line-height: 1.5em;

    > .icon {
      position: absolute;
      top: 0.2em;
      left: -1em;
    }
  }
`

const IconList = ({ children }) => (
  <ul css={iconListStyles} className="spacing-xsmall">
    {children}
  </ul>
)

const IconListItem = ({ icon, color, children }) => (
  <li>
    <Icon icon={icon} color={color} />
    <div
      css={css`
        margin-left: 0.5em;
      `}>
      {children}
    </div>
  </li>
)

export { IconList, IconListItem }
