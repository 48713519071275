import React from 'react'
import DocumentLayout from '../DocumentLayout'

// Panels
import Hero from '../ui/Hero'
import WhatIsQuestionable from '../ui/WhatIsQuestionable'
import PayAsYouGo from '../ui/PayAsYouGo'

const Index = () => (
  <DocumentLayout className="homepage">
    <Hero />
    <WhatIsQuestionable />
    <PayAsYouGo />
  </DocumentLayout>
)

export default Index
