import React from 'react'
import Link from 'gatsby-link'
import { css } from '@emotion/core'
import { Columns, Column } from 'react-flex-columns'
import { useMediaLayout } from 'use-media'
import Centered from '../Centered'
import { ArrowList, ArrowListItem } from '../ui/ArrowList'

const centeredStyles = css`
  padding-top: 2rem;
  padding-bottom: 2rem;
`

function PayAsYouGo() {
  const stack = useMediaLayout({ maxWidth: '800px' })

  return (
    <Centered className="spacing" css={centeredStyles}>
      <Columns gutterSize={3} stack={stack}>
        <Column flex className="spacing">
          <h1>Pay-as-you-go Pricing</h1>
          <p>
            Creating your own tests and questions is free, and you can create as many as you need. When you're ready to
            test someone, send them a test invite. The <Link to="/pricing">Pay-As-You-Go</Link> Plan only charges for
            the number of people who actually take your test. Monthly subscriptions are not required.
          </p>
          <p>
            <strong>What if I don't want to make questions?</strong>
          </p>
          <p>
            Even though authoring your own tests and questions is free, you can also use our Professional Tests we've
            created for you. Learn more about our <Link to="/pricing">Pricing and Plans.</Link>
          </p>
          <div>
            <Link to="/pricing" className="action-button">
              See Pricing Options
            </Link>
          </div>
        </Column>
        <Column flex className="spacing">
          <ArrowList>
            <ArrowListItem
              icon="test"
              title={
                <span>
                  Create Tests or <Link to="/professional-tests">Use Ours</Link>
                </span>
              }>
              <p>Create your own tests or use ours. Then enable billing which can be canceled at any time.</p>
            </ArrowListItem>
            <ArrowListItem icon="share" title="Invite Test Takers to the Test">
              <p>
                Create an invite-link to share with test takers or use the groups feature to create groups of test
                takers to invite all at once.
              </p>
            </ArrowListItem>
            <ArrowListItem icon="line-graph" title="See Results and Analytics">
              <p>Use the Gap Analysis report to see what subject areas test takers perform well or poor in.</p>
            </ArrowListItem>
          </ArrowList>
        </Column>
      </Columns>
    </Centered>
  )
}

export default PayAsYouGo
