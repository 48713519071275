import React, { Fragment, useState } from 'react'
import classnames from 'classnames'
import Link from 'gatsby-link'
import { css } from '@emotion/core'
import Centered from '../Centered'
import Button from '../shared/ui/Button'
import { IconList, IconListItem } from '../shared/ui/IconList'
import Laptop from '../ui/Laptop'
import theme from '../styles/theme'

const breakpointOne = '800px'
const breakpointTwo = theme.panelBreakpoint

const tabListStyles = css`
  margin-top: 4em; /* offset for ::before content */
  background-color: #eefaff;

  .tab-list {
    position: relative;
    text-align: center;

    &::before {
      @media (min-width: ${breakpointOne}) {
        content: 'what is Questionable.io?';
        display: block;
        position: absolute;
        top: -0.9em;
        color: #bdd3dc;
        font: 1.5em ${theme.scriptFontFamily};
      }
    }

    .tab {
      display: inline-block;
      width: 50%;
      padding: 1em;

      @media (min-width: ${breakpointOne}) {
        color: ${theme.brightBlue};
        margin-bottom: -0.5em;
        font-size: 1em;
        width: auto;
      }

      @media (min-width: ${breakpointTwo}) {
        font-size: 1.2em;
      }
    }

    .tab.active {
      position: relative;
      color: #1e91b1;
      font-weight: 600;

      @media (min-width: ${breakpointOne}) {
        &::before,
        &::after {
          content: '';
          display: block;
          width: 100vw;
          height: 0;
          position: absolute;
          z-index: 10;
          top: calc(100% - 0.5em);
          border: 0.5em solid #eefaff;
          border-left-color: transparent;
          border-right-color: transparent;
          border-bottom-color: transparent;
        }
        &::before {
          right: 50%;
        }
        &::after {
          left: 50%;
        }
      }
    }
  }
`
const tabPanelsStyles = css`
  background: linear-gradient(115deg, ${theme.blue}, ${theme.green});
  position: relative;

  @media (min-width: ${breakpointOne}) {
    &::before {
      content: '';
      display: block;
      width: 0;
      border: 100vw solid transparent;
      border-top: none;
      border-bottom: 4em solid #fff;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  .tab-panels {
    padding: 2em 0;
    color: #fff;
    position: relative;

    h1,
    a {
      color: #fff;
    }

    @media (min-width: ${breakpointOne}) {
      margin-bottom: 7em;
      padding-bottom: 16em; /* Gives laptop some space to live in */
    }

    @media (min-width: ${breakpointTwo}) {
      margin-bottom: 7em;
      padding-bottom: 0;
      height: 27em;
    }
  }

  .laptop {
    display: none;

    @media (min-width: ${breakpointOne}) {
      display: block;
      position: absolute;
      font-size: 0.6em;
      bottom: -7em;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (min-width: ${breakpointTwo}) {
      font-size: 0.8em;
      bottom: -5.5em;
      left: -4em;
      transform: translateX(0);
    }
  }

  .tab-content {
    display: none;
    position: relative;

    &.active {
      display: block;
    }
  }

  .tab-content > .content {
    h1 {
      font-weight: 300;
      margin: 0;
      line-height: 1.5em;
      font-size: 1.3em;

      @media (min-width: ${breakpointOne}) {
        font-size: 1.7em;
      }
    }

    .points {
      font-size: 1em;

      @media (min-width: ${breakpointTwo}) {
        margin-left: 30rem;
      }
    }
  }
`

function WhatIsQuestionable() {
  const [tabIndex, setTabIndex] = useState(0)
  const tabLaptopUrl = ['author-question.png', 'tests.png', 'professional-tests.png', 'analytics.png']

  return (
    <Fragment>
      <div css={tabListStyles}>
        <Centered>
          <div className="tab-list">
            <Button onClick={() => setTabIndex(0)} className={classnames('tab', { active: tabIndex === 0 })}>
              Questions
            </Button>
            <Button onClick={() => setTabIndex(1)} className={classnames('tab', { active: tabIndex === 1 })}>
              Create Tests
            </Button>
            <Button onClick={() => setTabIndex(2)} className={classnames('tab', { active: tabIndex === 2 })}>
              Our Professional Tests
            </Button>
            <Button onClick={() => setTabIndex(3)} className={classnames('tab', { active: tabIndex === 3 })}>
              Analytics
            </Button>
          </div>
        </Centered>
      </div>

      <div css={tabPanelsStyles}>
        <Centered>
          <div className="tab-panels">
            <Laptop image={`${tabLaptopUrl[tabIndex]}`} />
            <div className={classnames('tab-content', { active: tabIndex === 0 })}>
              <div className="content">
                <h1>
                  We've worked hard to make the question authoring experience an enjoyable one. Change the language mode
                  on the Question or answer fields and experience a high quality code editor.
                </h1>
                <div className="points">
                  <IconList>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Create Your Own Questions, Or:
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Buy Our Professional Tests.
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Use Many Different Programming Language Modes For Your Question Or Answers.
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Real Code In Questions Means Better Assessment Of Real Skills, Not Academic Jargon And Terms.
                    </IconListItem>
                  </IconList>
                </div>
              </div>
            </div>

            <div className={classnames('tab-content', { active: tabIndex === 1 })}>
              <div className="content">
                <h1>
                  Build up your question bank with custom questions or questions acquired through our Professional
                  Tests. Then mix and match to create any number of tests from your bank of questions.
                </h1>
                <div className="points">
                  <IconList>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Have Unlimited Tests In Your Account.
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      See Aggregate Testing Statistics And Analytics
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Invite Test Takers Via Sharable Link or Groups
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Edit Settings On A Test or Invite Basis
                    </IconListItem>
                  </IconList>
                </div>
              </div>
            </div>

            <div className={classnames('tab-content', { active: tabIndex === 2 })}>
              <div className="content">
                <h1>
                  Besides making your own tests, you can use our{' '}
                  <Link to="/professional-tests">Professional Tests</Link> with a Subscription Plan. Professional Tests
                  are created by hand-picked industry experts.
                </h1>
                <div className="points">
                  <IconList>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Get Updates For Free.
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Edit Any Of The Questions You Purchase.
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Re-use Questions You've Purchased On Your Custom Tests
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Tests Are Created For Many Different Programming Languages, Frameworks, & Popular Coding
                      Libraries.
                    </IconListItem>
                  </IconList>
                </div>
              </div>
            </div>

            <div className={classnames('tab-content', { active: tabIndex === 3 })}>
              <div className="content">
                <h1>
                  One of our best analytics is the Gap Analysis report. This unique report will indicate how well a test
                  taker did on a subject-by-subject basis of the test.
                </h1>
                <div className="points">
                  <IconList>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Gap Analysis Indicates Areas of Excellent Skill and Weak Skills On A Per-Subject Basis.
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      This Can Be Used to Gauge Training Needs.
                    </IconListItem>
                    <IconListItem icon="arrow-circle-right" color="white">
                      Share Test Results With Colleagues.
                    </IconListItem>
                  </IconList>
                </div>
              </div>
            </div>
          </div>
        </Centered>
      </div>
    </Fragment>
  )
}

export default WhatIsQuestionable
